import React, { useState } from "react";
import AnimatedSection from 'components/AnimatedSection/AnimatedSection';
import ModalRegistration from "components/RegistrationModal/RegistrationModal";
import { Link } from "react-router-dom";
import "./AcademyCBATop.css";

const AcademyCBATop = () => {
    const [modal2Open, setModal2Open] = useState(false);
    const handleShowRegistration = () => {
        if (localStorage.getItem("token")) {
            setModal2Open(true);
        } else {
            window.location.href = "/signin"
        }
    }

    return (
        <div className="academy-slide">
            <div className="academy-slide-content">
                <Link to="#" className="slide-subtitle" onClick={handleShowRegistration}>DEVELOPING THE FUTURE OF BASKETBALL</Link>
                {modal2Open && <ModalRegistration modal2Open={modal2Open} setModal2Open={setModal2Open} />}
                <AnimatedSection type="top">
                    <h1 className="slide-title">COASTAL BASKETBALL <br />
                        ACADEMY <span>[CBA]</span>
                    </h1>
                </AnimatedSection>
                <p className="slide-description">
                    <AnimatedSection type="right">
                        Coastal Basketball Academy (CBA) is dedicated to cultivating the
                        next generation of basketball talent. With programs tailored to
                        different age groups and skill levels, CBA provides a comprehensive
                        development pathway from grassroots to elite competition.
                    </AnimatedSection>
                </p>
            </div>
        </div>
    )
}

export default AcademyCBATop;