import React from "react";
import { withRouter } from 'react-router-dom';
import AcademyCBATop from "components/AcademyCBA/AcademyCBATop/AcademyCBATop";
import AcademyCBAPrograms from "components/AcademyCBA/AcademyCBAProgram/AcademyCBAProgram";
import AcademyCBAEnrolment from "components/AcademyCBA/AcademyCBAEnrolment/AcademyCBAEnrolment";
import HomeLayout from "layouts/HomeLayout/HomeLayout";
import { Helmet } from "react-helmet-async";

function AcademyCBA(props) {
  return (
    <HomeLayout>
      <Helmet>
        <title>Coastal Basketball ACADEMY [CBA]</title>
        <meta name="description" content="Coastal Basketball Academy (CBA) is dedicated to cultivating the 
                    next generation of basketball talent. With programs tailored to 
                    different age groups and skill levels, CBA provides a comprehensive 
                    development pathway from grassroots to elite competition." />
      </Helmet>
      <AcademyCBATop />
      <AcademyCBAPrograms />
      <AcademyCBAEnrolment />
    </HomeLayout>
  );
}

export default withRouter(AcademyCBA);
