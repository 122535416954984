import React from 'react';
import AnimatedSection from 'components/AnimatedSection/AnimatedSection';
import TypingEffect from 'components/AnimatedSection/TypingEffect';
import './BaseMembershipWidget.css';
import { Card, Col, Row } from 'antd';
import ImgRepreOpp1 from '../../../assets/png/member_player.png';

const BaseMembershipWidget = () => {
    return (
        <div className='base-membership-top-rect'>
            <h1 className='typo-blue font-48'><TypingEffect text='Annual Membership – Support & Play!' /></h1>
            <Row className='membership-base-content' gutter={[32, 32]} justify="center" align="top">
                <Col xs={24} md={12}>
                    <Card
                        bordered={false}
                        cover={<img alt="Purchase membership" className='membership-base-player-img' src={ImgRepreOpp1} />}
                    >
                    </Card>
                </Col>
                <Col xs={24} md={12}>
                    <div className="base-membership-info">
                        <h2><TypingEffect text='Purchase membership' /></h2>
                        <p className='membership-desc-p'>
                            To participate in competitions, camps, clinics, and to purchase additional access to the Coastal Basketball Centre (CBC), each player or supporter will need to purchase an annual Coastal Foundation Membership.
                        </p>
                        <p className='membership-desc-p'>
                            This membership directly supports basketball competitions and facilities in the South West Region.
                        </p>
                        <p className='membership-desc-p'>
                            This membership is unlimited in availability.
                        </p>
                        <p className='membership-desc-p'>
                            After purchasing the foundation membership, players can register for events and upgrade to additional membership levels.
                        </p>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default BaseMembershipWidget;