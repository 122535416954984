import React, { useState, useEffect } from 'react';
import { Table, Button, Input, Popconfirm, Form, Tooltip, Row, Col, Select, Tabs, Modal, message, Spin } from 'antd';
import Toast from 'components/Toast/Toast';
import './index.css';
import UserLayout from 'layouts/UserLayout/UserLayout';
import MembershipWidget from 'components/MembershipWidget/MembershipWidget';
import { showUpdateSubscribeDlg } from 'components/Stripe/UpdateSubscribeDlg/showUpdateSubscribeDlg';
import { apiPost } from 'ajax/apiServices';
import { UrlCancelSubscription, UrlGetSubscription, UrlUpdateSubscription } from 'ajax/apiUrls';
import { useDispatch, useSelector } from 'react-redux';
import { updateSubscription } from '../../../redux/reducers/subscriptionSlice';

const Subscription = ({ history }) => {
  const userInfo = useSelector((state) => state.user);
  const userSubscription = useSelector((state) => state.subscription);

  const dispatch = useDispatch();
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [selSubscription, setSelSubscription] = useState(null);
  const [baseMembership, setBaseMembership] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      history.push('/signin');
    } else {
      fetchData();
    }
  }, []);
  const fetchData = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('user_id', userInfo.id);
    formData.append('email', userInfo.email);
    apiPost(UrlGetSubscription, formData)
      .then((res) => {
        setLoading(false);
        let tmpList = [...res.packages]
        let tmpSubList = []
        tmpList.map((x, index) => {
          let tmpObj = { ...x }
          tmpObj = { ...tmpObj, checked: false }
          if (parseInt(tmpObj.package) == 1) {
            setBaseMembership(tmpObj);
          } else {
            tmpSubList = [...tmpSubList, tmpObj]
          }
        })
        setSubscriptionList(tmpSubList);
        if (res.cur_package) {
          setCurrentSubscription({ ...res.cur_package })
        } else {
          setCurrentSubscription(null);
        }
        dispatch(updateSubscription(res.subscription));
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleCancel = async () => {
    const formData = new FormData();
    formData.append('user_id', userInfo.id);
    formData.append('email', userInfo.email);
    apiPost(UrlCancelSubscription, formData)
      .then((res) => {
        Toast("Membership canceled successfully", 1);
        setSelSubscription(null);
        onGetCurrentSubscription();
      })
      .catch((err) => {
        Toast(err, 2);
      });
  }
  const handleUpdate = async (tmpSelSubscription = null) => {
    if (tmpSelSubscription == null && selSubscription == null) {
      Toast("Please select package", 2);
      return;
    }

    if (currentSubscription) {
      onUpdateSubscription(tmpSelSubscription)
    } else {
      let dlgRes = await showUpdateSubscribeDlg({
        selSubscription: tmpSelSubscription ? tmpSelSubscription : selSubscription,
        userInfo: userInfo
      })
      if (dlgRes == null) return;
      setSelSubscription(null);
      onGetCurrentSubscription();
    }
  }

  const onUpdateSubscription = async (tmpSelSubscription = null) => {
    const formData = new FormData();
    formData.append('user_id', userInfo.id);
    formData.append('email', userInfo.email);
    formData.append('subscription_plan_id', tmpSelSubscription ? tmpSelSubscription.id : selSubscription.id);
    apiPost(UrlUpdateSubscription, formData)
      .then((res) => {
        Toast("Membership updated successfully", 1);
        setSelSubscription(null);
        onGetCurrentSubscription();
      })
      .catch((err) => {
        Toast(err, 2);
      });

  }


  const onGetCurrentSubscription = async () => {
    setLoading(true);
    setTimeout(() => {
      const formData = new FormData();
      formData.append('user_id', userInfo.id);
      apiPost(UrlGetSubscription, formData)
        .then((res) => {
          setLoading(false);
          let tmpList = [...res.packages]
          let tmpSubList = []
          tmpList.map((x, index) => {
            let tmpObj = { ...x }
            tmpObj = { ...tmpObj, checked: false }
            if (parseInt(tmpObj.package) == 1) {
              setBaseMembership(tmpObj);
            } else {
              tmpSubList = [...tmpSubList, tmpObj]
            }
          })
          setSubscriptionList(tmpSubList);
          if (res.cur_package) {
            setCurrentSubscription({ ...res.cur_package })
          } else {
            setCurrentSubscription(null);
          }
          dispatch(updateSubscription(res.subscription));
        })
        .catch((err) => {
          setLoading(false);
        });
    }, 2000)

  }

  const onBuy = (index) => {
    let tmpSelSubscription = null
    if (index == -1) {
      setSelSubscription({ ...baseMembership })
      tmpSelSubscription = { ...baseMembership };
      setBaseMembership({ ...baseMembership, checked: true })
      let tmpList = [...subscriptionList]
      tmpList = tmpList.map((x, key) => {
        let tmpObj = { ...x }
        tmpObj.checked = false;
        return tmpObj
      })
      setSubscriptionList(tmpList)
    } else {
      Toast("For the all access membership packages, we will open from the Thursday 17th April", 2);
      return;
      setBaseMembership({ ...baseMembership, checked: false })

      let tmpList = [...subscriptionList]
      tmpList = tmpList.map((x, key) => {
        let tmpObj = { ...x }
        if (key == index) {
          tmpObj.checked = true;
          setSelSubscription({ ...tmpObj })
          tmpSelSubscription = { ...tmpObj };
        } else {
          tmpObj.checked = false;
        }
        return tmpObj
      })
      setSubscriptionList(tmpList)
    }

    handleUpdate(tmpSelSubscription);
  }
  const onCancel = (index) => {
    let tmpSelSubscription = null
    if (index == -1) {
      setSelSubscription({ ...baseMembership })
      tmpSelSubscription = { ...baseMembership };
      setBaseMembership({ ...baseMembership, checked: true })
      let tmpList = [...subscriptionList]
      tmpList = tmpList.map((x, key) => {
        let tmpObj = { ...x }
        tmpObj.checked = false;
        return tmpObj
      })
      setSubscriptionList(tmpList)
    } else {
      Toast("For the all access membership packages, we will open from the Thursday 17th April", 2);
      return;
      setBaseMembership({ ...baseMembership, checked: false })
      let tmpList = [...subscriptionList]
      tmpList = tmpList.map((x, key) => {
        let tmpObj = { ...x }
        if (key == index) {
          tmpObj.checked = true;
          setSelSubscription({ ...tmpObj })
          tmpSelSubscription = { ...tmpObj };
        } else {
          tmpObj.checked = false;
        }
        return tmpObj
      })
      setSubscriptionList(tmpList)
    }
    handleCancel();
  }
  return (
    <UserLayout>
      <Spin spinning={loading}>
        <div className='user-membership-top-rect'>
          <div className='page-title'>Membership</div>
          <Row className="table-nav" align={'middle'}>
            <Col className='d-flex content-title'>
              Current Membership: {currentSubscription ? userSubscription.status : "N/A"}
            </Col>
            {/* <Col>
            {currentSubscription?.id == selSubscription?.id ?
              <>
                {currentSubscription ? <Button type="primary" onClick={handleCancel} style={{ height: '38px', background: '#a7192f', color: 'white', fontWeight: 600, fontFamily: "ChakraPetch-Regular" }}>
                  Cancel Membership
                </Button> : <></>}
              </> : selSubscription ? <Button type="primary" onClick={handleUpdate} style={{ height: '38px', fontWeight: 600, fontFamily: "ChakraPetch-Regular" }}>
                Update Membership
              </Button> : <></>}
          </Col> */}
          </Row>
          <div style={{ marginTop: 20 }}>
            <MembershipWidget
              baseSubscriptionInfo={baseMembership}
              setBaseMembership={setBaseMembership}
              subscriptionList={subscriptionList}
              setSubscriptionList={setSubscriptionList}
              currentSubscription={currentSubscription}
              selSubscription={selSubscription}
              setSelSubscription={setSelSubscription}
              loading={loading}
              setLoading={setLoading}
              onBuy={onBuy}
              onCancel={onCancel}
            />
          </div>
        </div>
      </Spin>
    </UserLayout>
  );
};

export default Subscription;